















import { Component, Vue, Prop } from "vue-property-decorator";
import UtilityService from "@/services/utility_service";
import { UtilityAPIResponse } from "@/models/api_res";
import { APIError, AuthError } from "@/services/error_service";
import { EventBus } from "@/events";

@Component
export default class Download extends Vue {
  @Prop() selectedFile: File | null = null;
  protected utilityService = new UtilityService();

  get samplecsv() {
    return require("../../assets/files/test_product_import.csv");
  }

  get selected(): string {
    let text = "No file selected."
    if (this.selectedFile) {
      text = this.selectedFile.name;
    }
    return text;
  }

  protected clear(): void {
    this.$emit('dimFile', null)
  }
  
protected openFileManager(): void {
    (this.$refs.fileInputDl as HTMLInputElement).click();
  }

  protected selectFile(): void {
    const file = (this.$refs.fileInputDl as HTMLInputElement).files![0];
    this.$emit('dlFile', file)
  }


}
