












































import { Component, Vue } from "vue-property-decorator";
import UtilityService from "@/services/utility_service";
import { UtilityAPIResponse } from "@/models/api_res";
import { AuthError } from "@/services/error_service";
import Product from "./Product.vue";
import Shipping from "./Shipping.vue";
import Dimension from "./Dimension.vue";
import Download from "./Download.vue";
import UIkit from "uikit";
import { LoaderComponent } from "node_modules/vue-loading-overlay/types/loading";

@Component({
  components: {
    Product,
    Shipping,
    Dimension,
    Download,
  },
})
export default class Utility extends Vue {
  protected product: File | null = null;
  protected dim: File | null = null;
  protected shipping: File | null = null;
  protected download: File | null = null;
  protected utilityService = new UtilityService();
  protected showError = false;
  protected loader: LoaderComponent | null = null;
  protected errors: string[] = [];
  protected productLoader = false;
  protected shippingLoader = false;
  protected dimLoader = false;
  protected downloadLoader = false;

  get sampleProduct() {
    return require("../../assets/files/test_product_import.csv");
  }

  get sampleShipping() {
    return require("../../assets/files/test_shipping_import.csv");
  }

  get sampleDim() {
    return require("../../assets/files/test_dimensions_import.csv");
  }

  get sampleDl() {
    return require("../../assets/files/test_downloads_import.csv");
  }

  protected showSpinner(): LoaderComponent {
    const loader = this.$loading.show(
      {
        isFullPage: true,
        canCancel: false,
      },
      { before: this.$createElement("h1", "Uploading...") }
    );
    return loader;
  }

  protected hideSpinner(): void {
    if (
      !this.productLoader &&
      !this.shippingLoader &&
      !this.dimLoader &&
      !this.downloadLoader &&
      this.loader
    ) {
      this.loader.hide();
    }
  }

  protected close(): void {
    this.$emit("close");
    this.errors = [];
    this.showError = false;
  }

  protected productFile(file: File): void {
    this.product = file;
  }

  protected dimFile(file: File): void {
    this.dim = file;
  }

  protected shippingFile(file: File): void {
    this.shipping = file;
  }

  protected dlFile(file: File): void {
    this.download = file;
  }

  protected upload(): void {
    this.loader = this.showSpinner();
    this.errors = [];
    this.showError = false;
    if (this.product) {
      this.productLoader = true;
      this.sendProduct();
    }
    if (this.shipping) {
      this.shippingLoader = true;
      this.sendShipping();
    }
    if (this.dim) {
      this.dimLoader = true;
      this.sendDim();
    }
    if (this.download) {
      this.downloadLoader = true;
      this.sendDownload();
    } else if (!this.product && !this.shipping && !this.dim && !this.download) {
      this.hideSpinner();
      UIkit.notification(`<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <span>No file selected.</span>
    </div>
  </div>`);
    }
  }

  protected async sendProduct(): Promise<void> {
    const productRes = await this.uploadProduct();
    this.productLoader = false;
    if (productRes && productRes.errors.length > 0) {
      productRes.errors.forEach((err) => {
        this.errors.push(
          `<p>Product CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (productRes) {
      UIkit.notification(`<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <span>Product spreadsheet uploaded successfully.</span>
    </div>
  </div>`);
    }
    this.hideSpinner();
  }

  protected async sendShipping(): Promise<void> {
    const shippingRes = await this.uploadShipping();
    this.shippingLoader = false;
    if (shippingRes && shippingRes.errors.length > 0) {
      shippingRes.errors.forEach((err) => {
        this.errors.push(
          `<p>Product CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (shippingRes) {
      UIkit.notification(`<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <span>Shipping spreadsheet uploaded successfully.</span>
    </div>
  </div>`);
    }
    this.hideSpinner();
  }

  protected async sendDim(): Promise<void> {
    const dimRes = await this.uploadDim();
    this.dimLoader = false;
    if (dimRes && dimRes.errors.length > 0) {
      dimRes.errors.forEach((err) => {
        this.errors.push(
          `<p>Dimension CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (dimRes) {
      UIkit.notification(`<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <span>Dimension spreadsheet uploaded successfully.</span>
    </div>
  </div>`);
    }
    this.hideSpinner();
  }

  protected async sendDownload(): Promise<void> {
    const dlRes = await this.uploadDownloads();
    this.downloadLoader = false;
    if (dlRes && dlRes.errors && dlRes.errors.length > 0) {
      dlRes.errors.forEach((err) => {
        this.errors.push(
          `<p>Download CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (dlRes) {
      UIkit.notification(`<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <span>Download spreadsheet uploaded successfully.</span>
    </div>
  </div>`);
    }
    this.hideSpinner();
  }

  protected async uploadProduct(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadProductCsv(
        this.product as File
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Product CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.product = null;
    return data;
  }

  protected async uploadDim(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadDimCsv(this.dim as File);
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Dimension CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.dim = null;
    return data;
  }

  protected async uploadShipping(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadShippingCsv(
        this.shipping as File
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Shipping CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.shipping = null;
    return data;
  }
  protected async uploadDownloads(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadDownloadsCsv(
        this.download as File
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Download CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.download = null;
    return data;
  }
}
